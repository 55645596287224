/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mes factures';
export const txtnombredoc = 'document';
export const txtnombredocpluriel = 'documents';
export const titleAlveole = 'Mes factures';

// TODO arriver a faire fonctionner
export const alveoleLogoSvg = "./../images/icon-alv-factures.svg";

/************************ DeV ******************************************************/
export const zAPIdocuments = "https://api-mesfactures-dev.cristal-hub.fr/customer-documents";
export const zUrlBack = "https://api-mesfactures-dev.cristal-hub.fr";
export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";
export const urlZeenDoc = "https://www.preprod.crm-cristal.fr/enjinapi/Zeendoc"

/************************ LOCAL DeV ************************************************/
// export const zUrlBack = "https://localhost:8000"
// export const zAPIdocuments = "https://localhost:8000/customer-documents";
// export const zUrlBackNotifications = "https://localhost:8001";

export const Typedoc =[
    { id: 6, title: 'Factures' },
    { id: 7, title: 'Avoirs' },
];
export const Typedocid = [6,7];

/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLhome = "https://home-dev.cristal-hub.fr/customer";
export const zURLprofile = "https://profile-dev.cristal-hub.fr";
